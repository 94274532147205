import LanguageHeadTagsDynamic from "@/components/languageHeadTagsDynamic/languageHeadTagsDynamic";
import DraftPageRenderer from "@/components/page/draftpageRenderer";
import PageRenderer from "@/components/page/pageRenderer";
import useStartEditMode from "@/hooks/useStartEditMode";
import { getCmsPagesServerSide } from "@/services/cmsService/cmsServerService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { getPageServerSide } from "@/services/pageService/pageServerService";
import { useAppSelector } from "@/store/store";
import { PbPage } from "@/types/content-elements";
import { ROOT_PAGE_URL } from "@/utils/constants";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import {
    getServerSideTranslationNamespaces,
    isCmsAccessTokenValid,
    isNonDesktopDevice,
} from "@/utils/serverUtil";
import { removeUrlQueryParamsFromWindowHistory } from "@/utils/urlUtil";
import { buildTags, getPageTitle } from "@/utils/util";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

/**
 * Home / index component
 * 
 * This component is used to render pages in the application dynamically. 
 * It handles server-side rendering to ensure that content is 
 * fetched and rendered on the server before being sent to the client,
 * providing SEO benefits and a faster initial load time.
 * 
 * This page utilizes pageRenderer.tsx and draftPageRenderer.tsx 
 * for rendering.
 * 
 * @param {UrlProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered page content.
 */
export default function Home(
  props: InferGetServerSidePropsType<typeof getServerSideProps>
) {
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);

  removeUrlQueryParamsFromWindowHistory();
  useStartEditMode(props.startEditMode);
  return (
    <>
      <Head>
        <title>{getPageTitle(props.page)}</title>
        {buildTags(props.page.seoSettings).map((metaTag) => metaTag)}
        {props.page?.canonicalLinkUrl && (
          <link rel="canonical" href={props.page.canonicalLinkUrl} />
        )}
      </Head>
      <LanguageHeadTagsDynamic
        locale={props.page?.locale ?? null}
        urlPrefix={""}
        url={props.page?.url ?? null}
        localizations={props.page?.localizations ?? null}
        customCanonicalTag={props.page?.canonicalLinkUrl ?? null}
      />
      <div className="page content">
        {editMode ? (
          <DraftPageRenderer availableElements={{}} isMobile={props.isMobile} />
        ) : (
          <PageRenderer
            data={props.page}
            showCmsPageWarnings={props.showCmsPageWarnings}
            isMobile={props.isMobile}
          />
        )}
      </div>
      <style jsx>
        {`
          .content {
            max-width: ${globalConfig?.layout?.containerWidth || "100%"};
            margin-left: auto;
            margin-right: auto;
          }
        `}
      </style>
    </>
  );
}

/**
 * Fetches server-side props for the page.
 * 
 * This function performs server-side rendering to fetch necessary 
 * data for the page. It checks if the content manager is authenticated
 * and fetches the appropriate page data accordingly. It also handles 
 * translations and determines if the request is coming from a mobile device.
 * 
 * @param {object} context - The context object for server-side rendering.
 * @returns {Promise<object>} The server-side properties for the page.
 */
export const getServerSideProps = (async (context) => {
  const { req, query, locale } = context;

  let result;
  let startEditMode = false;
  let showCmsPageWarnings = false;
  const cmsAccessToken = await isCmsAccessTokenValid(context.req);

  if (cmsAccessToken) {
    result = await getCmsPagesServerSide(
      req,
      ROOT_PAGE_URL,
      locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!
    );
    if (query.edit === "true") {
      startEditMode = true;
    }
    showCmsPageWarnings = true;
  } else {
    result = await getPageServerSide(
      ROOT_PAGE_URL,
      locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!
    );

    // root page only behaviour for guest users
    // if the request failed and it is not the default locale
    // try to redirect to the default locale if it exists
    if (
      !isLocaleDefaultLocale(
        locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!
      ) &&
      (!result || !result.success)
    ) {
      result = await getPageServerSide(
        ROOT_PAGE_URL,
        process.env.NEXT_PUBLIC_DEFAULT_LOCALE!
      );
      if (result.success) {
        return {
          redirect: {
            permanent: false,
            destination: `/${process.env.NEXT_PUBLIC_DEFAULT_LOCALE!}`,
          },
        };
      }
    }
  }

  if (!result || !result.success) {
    return { notFound: true };
  }

  const page = result.data;
  const isMobile = isNonDesktopDevice(context.req.headers["user-agent"]);

  return {
    props: {
      ...(await serverSideTranslations(
        context.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
        getServerSideTranslationNamespaces()
      )),
      page: page,
      // pageTitle: getMetaTitleOrNull(pageData),
      startEditMode: startEditMode,
      showCmsPageWarnings: showCmsPageWarnings,
      currentLocale: locale,
      isMobile: isMobile,
    },
  };
}) satisfies GetServerSideProps<{
  page: PbPage;
  startEditMode: boolean;
  showCmsPageWarnings: boolean;
  currentLocale: string | undefined;
  isMobile: boolean;
}>;
